import React from 'react'

import RootLayout from '@components/rootLayout'
import RoutedLayout from '@components/routedLayout'
import { About } from '@components/about'
import SEO from '@src/components/seo'

const AboutPage = () => (
  <RootLayout>
    <SEO title='About Me' desc='A bit of information about me, my path in photography and photography software projects I have developed.' />
    <RoutedLayout />
  </RootLayout>
)

export default AboutPage
